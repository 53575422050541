<template >
  <ion-page>
    <Header type="0" title="检查站接收检查件"> </Header>

    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>零件编号</div>
          <div class="right">
            <div class="searchBox">
              <input
                @input="scan"
                placeholder="请扫描零件二维码"
                v-model="partCode"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div v-for="item in tableList" :key="item.id" class="app-container">
        <div class="item" style="padding-bottom: 15px">
          <div class="item-label">{{ item.partCode }}</div>
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 25px"
        >
          <div class="item-label">入库单接收数量</div>
          <div class="item-content">{{ item.arrivalNum }}</div>
        </div>
        <div class="item">
          <div class="item-label">实际到货数量</div>
          <div class="item-content">
            {{ item.actArrivalNum }}
          </div>
        </div>
      </div>
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>
    <ion-footer>
      <ion-button expand="block" @click="commit">提交</ion-button>
    </ion-footer>
    <scanner v-if="showScan" @closeScan="closeScan"></scanner>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  loadingController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { qrCode } from "ionicons/icons";
import {
  getCheckItem,
  sendCheckWork,
} from "@/api/storeManagement/checkpointPutinStorage";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import SystemUtils from "@/utils/SystemUtils";
import scanner from "@/components/cordova/qrScanner.vue";
import SearchBar from "@/components/SearchBar.vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
    Header,
    uploadImg,
    CardLoading,
    ionAlert,
    SearchBar,
    scanner,
  },
  data() {
    return {
      qrCode,
      tableList: [],
      islist: false, // isloading
      isOpenRef: false, // 确认框
      taskCode: "", //任务单号
      showScan: false,
      partCode: "",
    };
  },
  mounted() {},
  methods: {
    getList(params) {
      this.islist = true;
      getCheckItem(params).then((res) => {
        this.islist = false;
        if (res.data.isError || !res.data.data.isSuccess) {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
          return false;
        }
        this.tableList = res.data.data.resultData || [];
        for (var i = 0; i < this.tableList.length; i++) {
          this.tableList[i].actArrivalNum = 0;
          if (this.tableList[i].partCode == params.partCode) {
            this.tableList[i].actArrivalNum = 1;
          }
        }
      });
    },
    transfer(type) {
      console.log("img");
      this.showScan = true;
    },

    async commit() {
      const _this = this;
      if (_this.tableList.length == 0) {
        Utils.presentToastTopWarning("无可接收零件", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });

              await loading.present();

              sendCheckWork(_this.tableList).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("提交成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "warning"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    // 弹出任务单modal
    getTaskCode() {
      menuController.enable(true, "searchTask");
      menuController.open("searchTask");
    },
    // 更改接收数量
    // changeNum(item) {
    //   console.log(item);
    //   if (item.actArrivalNum < item.arrivalNum) {
    //     item.arrivalNum = item.actArrivalNum;
    //     Utils.presentToastTopWarning(
    //       "到货数量与入库数量总和不能大于采购数量",
    //       "danger"
    //     );
    //   }
    // },

    scan(val) {
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.partCode;
        if (scanVal.indexOf('"') > -1) {
          _this.partCode = "";
          if (
            scanVal.indexOf("stageCode") > -1 &&
            scanVal.indexOf("billId") > -1 &&
            scanVal.indexOf("partCode") > -1
          ) {
            var contents = JSON.parse(
              "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
            );
            _this.partCode = contents.partCode;
            if (
              _this.tableList.length > 0 &&
              _this.tableList.some((e) => {
                return e.partCode == contents.partCode;
              })
            ) {
              for (var i = 0; i < _this.tableList.length; i++) {
                if (_this.tableList[i].partCode == contents.partCode) {
                  _this.tableList[i].actArrivalNum = _this.tableList[i]
                    .actArrivalNum
                    ? ++_this.tableList[i].actArrivalNum
                    : 1;
                  if (
                    _this.tableList[i].actArrivalNum >
                    _this.tableList[i].arrivalNum
                  ) {
                    _this.tableList[i].actArrivalNum =
                      _this.tableList[i].arrivalNum;
                    Utils.presentToastTopWarning(
                      _this.tableList[i].partNameCN +
                        "实际到货数量不能大于接收数量",
                      "danger"
                    );
                  }
                }
              }
            } else {
              _this.getList({
                stageCode: contents.stageCode,
                billId: contents.billId,
                partCode: contents.partCode,
                partVersion: contents.partVersion,
              });
            }
          } else {
            _this.partCode = "";
            Utils.presentToastTopWarning("请扫描零件二维码", "danger");
          }
        }
      });
    },
    closeScan(contents) {
      this.showScan = false;
      if (
        contents.stageCode &&
        contents.billId &&
        contents.partCode &&
        contents.partVersion
      ) {
        if (
          this.tableList.length > 0 &&
          this.tableList.some((e) => {
            return e.partCode == contents.partCode;
          })
        ) {
          for (var i = 0; i < this.tableList.length; i++) {
            if (this.tableList[i].partCode == contents.partCode) {
              this.tableList[i].actArrivalNum = this.tableList[i].actArrivalNum
                ? ++this.tableList[i].actArrivalNum
                : 1;
              if (
                this.tableList[i].actArrivalNum > this.tableList[i].arrivalNum
              ) {
                this.tableList[i].actArrivalNum = this.tableList[i].arrivalNum;
                Utils.presentToastTopWarning(
                  this.tableList[i].partNameCN + "实际到货数量不能大于接收数量",
                  "danger"
                );
              }
            }
          }
        } else {
          this.getList({
            stageCode: contents.stageCode,
            billId: contents.billId,
            partCode: contents.partCode,
            partVersion: contents.partVersion,
          });
        }
      } else {
        Utils.presentToastTopWarning("请正确扫描二维码", "danger");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
      }
    }
    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
input {
  border: 0;
  outline: none;
  text-align: right;
}
</style>
import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 创建入库单
export function getCheckItem(data){
    console.log(SystemUtils.loginUser,123)
    return request('/storeStorageCheck/getCheckItem.do',"POST",Object.assign({userId:SystemUtils.loginUser.id},data))
}

// 创建入库单
export function sendCheckWork(data){
    return request('/storeStorageCheck/sendCheckWork.do',"POST",{userId:SystemUtils.loginUser.id,items:data})
}